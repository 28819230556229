.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper .content {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.wrapper .content .item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

