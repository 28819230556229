@keyframes slide-in-top {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.wrapper {
  animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;

  h2 {
    font-size: 73px;
    font-weight: 800;
    margin-left: -3px;
  }

  h3 {
    font-size: 30px;
    font-weight: 800;
    margin-top: -20px;
    color: orangered;
    margin-bottom: 20px;
  }

  .price {
    font-size: 40px;
    font-weight: 600;
    margin-top: 20px;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 14px;
    margin-top: 10px;
    display: flex;
    gap: 25px;

    li {
      font-weight: 700;
      font-size: 14px;
      &::marker {
        color: orangered;
      }
    }
  }

  .comparison {
    color: #9a9a9a;
    font-weight: 600;
    font-size: 14px;
    margin-top: 40px;
    display: flex;
    gap: 8px;

    img {
      width: 20px;
    }

    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}