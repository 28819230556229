.wrapper {
  .buy {
    color: #FF4500;
    text-decoration: underline;
    font-weight: 600;
  }

  table {
    tr {
      td {
        padding: 10px 0;
        text-align: center;

        .name {
          font-weight: 800;
        }
      }
    }
  }
}