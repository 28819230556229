.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;

  .logotype {
    width: 250px;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    gap: 40px;

    .link {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
      color: #9a9a9a;

      &:hover {
        cursor: pointer;
        color: #000000;
      }
    }
  }
}