.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;

    .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }



}