.wrapper {
  background-color: #FF4500;
  color: #ffffff;
  font-weight: 800;
  padding: 12px 30px 8px 30px;
  display: inline-block;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
}
.wrapper:hover {
  background-color: #ff2600;
  transform: scale(1.05);
}
.wrapper.outline {
  background: transparent;
  border: 1px solid #FF4500;
  color: #FF4500;
}

