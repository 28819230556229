@keyframes slide-in-top {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.wrapper {
  animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
.wrapper h2 {
  font-size: 73px;
  font-weight: 800;
  margin-left: -3px;
}
.wrapper h3 {
  font-size: 30px;
  font-weight: 800;
  margin-top: -20px;
  color: orangered;
  margin-bottom: 20px;
}
.wrapper .price {
  font-size: 40px;
  font-weight: 600;
  margin-top: 20px;
}
.wrapper .buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.wrapper ul {
  list-style: disc;
  padding-left: 14px;
  margin-top: 10px;
  display: flex;
  gap: 25px;
}
.wrapper ul li {
  font-weight: 700;
  font-size: 14px;
}
.wrapper ul li::marker {
  color: orangered;
}
.wrapper .comparison {
  color: #9a9a9a;
  font-weight: 600;
  font-size: 14px;
  margin-top: 40px;
  display: flex;
  gap: 8px;
}
.wrapper .comparison img {
  width: 20px;
}
.wrapper .comparison:hover {
  cursor: pointer;
  text-decoration: underline;
}
.wrapper .controls {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

