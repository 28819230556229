.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;

  .item {
    background-color: #dfdfdf;
    color: #000000;
    font-weight: 800;
    padding: 8px 15px 5px 15px;
    display: inline-block;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;

    &.outline{
      background: transparent;
      border: 1px solid #999999;
      color: #999999;

      &:hover{
        cursor: pointer;
        border: 1px solid #FF4500;
        color: #FF4500;
      }
    }
  }
}