.wrapper .buy {
  color: #FF4500;
  text-decoration: underline;
  font-weight: 600;
}
.wrapper table tr td {
  padding: 10px 0;
  text-align: center;
}
.wrapper table tr td .name {
  font-weight: 800;
}

