@keyframes slide-fwd-left {
  0% {
    transform: translateZ(0) translateX(200px);
  }
  100% {
    transform: translateZ(0) translateX(50px);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}



.wrapper{
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 1000px;

  .splash {
    height: 100%;
    width: 100%;
    background-image: url("/public/images/splash.svg");
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    img {
      margin-left: -50px;
      object-fit: cover;
      height: 85%;
      animation: slide-fwd-left 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) forwards;
    }
  }

  .nextButton {
    background-color: #000000;
    border-radius: 100%;
    height: 100px;
    width: 100px;
    position: absolute;
    z-index: 1;
    top: calc(50% - 50px);
    right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 21px 1px rgba(0,0,0,0.51);
    -webkit-box-shadow: 1px 1px 21px 1px rgba(0,0,0,0.51);
    -moz-box-shadow: 1px 1px 21px 1px rgba(0,0,0,0.51);
    cursor: pointer;

    img {
      height: 50%;
      width: 50%;
    }
  }
}